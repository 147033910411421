export function _GET_ROLE(role: string) {
	switch (role) {
		case 'leader':
			return 'Giám đốc';
		case 'admin':
			return 'Trưởng chuyên môn';
		case 'org_admin':
			return 'Quản lý cơ sở';
		case 'teacher':
			return 'Trị liệu viên';
		case 'accountant':
			return 'Kế toán';
		case 'support':
			return 'Hỗ trợ';
		default:
			return 'Hỗ trợ';
	}
}
