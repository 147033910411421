import { AxiosResponse } from 'axios';
import { $http } from '@/plugins/axios';

export const goalService = {
	goalByStudent: (id: string): Promise<AxiosResponse> => {
		return $http.get(`v1/staff/students/${id}/goals//with_skills`);
	},
	goalStoppedByStudent: (
		id: string,
		oldPayload?: any
	): Promise<AxiosResponse> => {
		return $http.get(
			`v1/staff/students/${id}/goals//with_skills?state=stopped`,
			oldPayload
				? {
					headers: {
						common: oldPayload,
					},
					// eslint-disable-next-line no-mixed-spaces-and-tabs
				}
				: {}
		);
	},
	goalLibByStudent(id: string, oldPayload?: any) {
		return $http.get(
			`v1/staff/students/${id}/goals/library`,
			oldPayload
				? {
					headers: {
						common: oldPayload,
					},
					// eslint-disable-next-line no-mixed-spaces-and-tabs
				}
				: {}
		);
	},
	goalCompletedByStudent: (
		id: string,
		oldPayload?: any
	): Promise<AxiosResponse> => {
		return $http.get(
			`v1/staff/students/${id}/goals//with_skills?state=completed`,
			oldPayload
				? {
					headers: {
						common: oldPayload,
					},
					// eslint-disable-next-line no-mixed-spaces-and-tabs
				}
				: {}
		);
	},
	allSkills() {
		return $http.get('/v1/staff/org_skills');
	},
	createGoal(student_id: string, data: any) {
		return $http.post(`/v1/staff/students/${student_id}/goals`, data);
	},
	createGoalLib(student_id: string, data: any) {
		return $http.post(
			`/v1/staff/students/${student_id}/goals/library`,
			data
		);
	},
	createGoalLibGroupClass(group_class_id: string, data: any) {
		return $http.post(
			`/v1/staff/group_classes/${group_class_id}/group_goals/library`,
			data
		);
	},
	updateGoal(student_id: string, id: string, data: any) {
		return $http.put(`/v1/staff/students/${student_id}/goals/${id}`, data);
	},
	updateGoalGroupClass(group_class_id: string, id: string, data: any) {
		return $http.put(
			`/v1/staff/group_classes/${group_class_id}/group_goals/${id}`,
			data
		);
	},
	stopGoals(student_id: string, data: any) {
		return $http.put(
			`/v1/staff/students/${student_id}/goals/batch_stop`,
			data
		);
	},
	stopGoal(student_id: string, id: string) {
		return $http.put(`/v1/staff/students/${student_id}/goals/${id}/stop`);
	},

	creatGoalFuture(student_id: string, plan_id: string, data: any) {
		return $http.post(
			`/v1/staff/students/${student_id}/plans/${plan_id}/goal_plans/create_with_new_goal`,
			data
		);
	},
	doGoal(student_id: string, plan_id: string, data: any) {
		return $http.post(
			`/v1/staff/students/${student_id}/plans/${plan_id}/goal_plans/create_from_stopped`,
			data
		);
	},
	doGoalCompleted(student_id: string, plan_id: string, data: any) {
		return $http.post(
			`/v1/staff/students/${student_id}/plans/${plan_id}/goal_plans/create_from_completed`,
			data
		);
	},
	doGoalByLib(student_id: string, plan_id: string, data: any) {
		return $http.post(
			`/v1/staff/students/${student_id}/plans/${plan_id}/goal_plans/create_from_library`,
			data
		);
	},
	deleteGoal(id: string, student_id: string, plan_id: string) {
		return $http.delete(
			`/v1/staff/students/${student_id}/plans/${plan_id}/goal_plans`,
			{
				data: {
					goal_id: id,
				},
			}
		);
	},
	deleteGoalLib(id: string, student_id: string) {
		return $http.delete(`/v1/staff/students/${student_id}/goals/${id}`);
	},
	goalDetail(id: string, student_id: string) {
		return $http.get(`/v1/staff/students/${student_id}/goals/${id}`);
	},
	goalSimpleDetail(id: string, student_id: string) {
		return $http.get(`/v1/staff/students/${student_id}/goals/${id}/show_simple_goal_parent`);
	},
	goalDetailWidthAssessment(id: string, student_id: string) {
		return $http.get(
			`/v1/staff/students/${student_id}/goals/${id}/detail_with_assessment`
		);
	},
	createActivity(student_id: string, data: any) {
		return $http.post(
			`/v1/staff/students/${student_id}/activities/create`,
			data
		);
	},
	updateActivity(id: string, student_id: string, data: any) {
		return $http.put(
			`/v1/staff/students/${student_id}/activities/${id}`,
			data
		);
	},
	deleteActivity(id: string, student_id: string) {
		return $http.delete(
			`/v1/staff/students/${student_id}/activities/${id}`
		);
	},
	createActivityGroupClass(
		group_class_id: string,
		group_goal_id: string,
		data: any
	) {
		return $http.post(
			`/v1/staff/group_classes/${group_class_id}/group_goals/${group_goal_id}/group_activities`,
			data
		);
	},
	updateActivityGroupClass(
		id: string,
		group_class_id: string,
		group_goal_id: string,
		data: any
	) {
		return $http.put(
			`/v1/staff/group_classes/${group_class_id}/group_goals/${group_goal_id}/group_activities/${id}`,
			data
		);
	},
	deleteActivityGroupClass(
		id: string,
		group_class_id: string,
		group_goal_id: string
	) {
		return $http.delete(
			`/v1/staff/group_classes/${group_class_id}/group_goals/${group_goal_id}/group_activities/${id}`
		);
	},
	fetchAllGoalLib(params: any = {}, isAdmin = true) {
		return $http.get(
			`v1/${isAdmin ? 'org_admin' : 'staff'}/goal_libraries`,
			{
				params,
			}
		);
	},
	fetchAllGoalLibSearch(params: any = {}, isAdmin = true) {
		return $http.get(
			`v1/${isAdmin ? 'org_admin' : 'staff'}/library_items/search`,
			{
				params,
			}
		);
	},
	fetchGoalLibDetail(id: string, isAdmin = true, params = {}) {
		return $http.get(
			`v1/${isAdmin ? 'org_admin' : 'staff'}/goal_libraries/${id}`,
			{ params }
		);
	},
	fetchAllGoalFolders(id: string, params: any = {}, isAdmin = true) {
		return $http.get(
			`v1/${isAdmin ? 'org_admin' : 'staff'
			}/goal_libraries/${id}/all_library_items`,
			{
				params,
			}
		);
	},
	createGoalLibrary(data: any, params: any = {}) {
		return $http.post(`v1/org_admin/goal_libraries`, data, { params });
	},
	updateGoalLibrary(id: string, data: any, params: any = {}) {
		return $http.put(`v1/org_admin/goal_libraries/${id}`, data, { params });
	},
	deleteGoalLibrary(id: string, params: any = {}) {
		return $http.delete(`v1/org_admin/goal_libraries/${id}`, { params });
	},
	updateGoalByParent(id: string, itemId: string, data: any) {
		return $http.put(
			`v1/org_admin/goal_libraries/${id}/library_items/${itemId}`,
			data
		);
	},
	createGoalFolder(id: string, data: any, params: any = {}) {
		return $http.post(
			`v1/org_admin/goal_libraries/${id}/library_items`,
			data,
			{ params }
		);
	},
	deleteGoalFolder(id: string, itemId: string, params: any = {}) {
		return $http.delete(
			`v1/org_admin/goal_libraries/${id}/library_items/${itemId}`,
			{ params }
		);
	},
	updateSmart(id: string, goal_library_id: string, data: any) {
		return $http.put(
			`v1/org_admin/goal_libraries/${goal_library_id}/library_items/${id}`,
			data
		);
	},
	addSkill(data: any) {
		return $http.post(`v1/org_admin/skills`, data);
	},
	updateSkillInGoals(id: string, data: any) {
		return $http.put(
			`v1/org_admin/goal_libraries/${id}/library_items/update_skill_id`,
			data
		);
	},
	updateParent(id: string, data: any) {
		return $http.put(
			`v1/org_admin/goal_libraries/${id}/library_items/update_parent_id`,
			data
		);
	},
	createGroup(studentId: string, data: any) {
		return $http.post(`v1/staff/students/${studentId}/groups`, data);
	},
	updateNameGroup(studentId: string, groupId: string, data: any) {
		return $http.put(
			`v1/staff/students/${studentId}/groups/${groupId}`,
			data
		);
	},
	updateGoalIntoGroup(studentId: string, groupId: string, data: any) {
		return $http.post(
			`v1/staff/students/${studentId}/groups/${groupId}/goals_groups`,
			data
		);
	},
	getListGroup(studentId: string) {
		return $http.get(`v1/staff/students/${studentId}/groups`);
	},
	getListGroupWithDetail(studentId: string, params: any) {
		return $http.get(`v1/staff/students/${studentId}/groups_with_goals`, {
			params,
		});
	},
	getListGroupWithDetailInLibs(studentId: string, params: any) {
		return $http.get(
			`v1/staff/students/${studentId}/groups_with_goals/library`, {
			params
		}
		);
	},
	getTwoLayerListGroupWithDetailInLibs(studentId: string) {
		return $http.get(
			`v1/staff/students/${studentId}/goals/two_layers_goals_library`
		);
	},
	getListGoalInLibraryBySkill(studentId: string, params: any) {
		return $http.get(
			`v1/staff/students/${studentId}/goals/library/list_goals_in_library_by_skill_id`, {
			params
		});
	},
	getListGroupWithGoals(studentId: string, monthlyId: string) {
		return $http.get(
			`v1/staff/students/${studentId}/groups_with_goals_for_monthly_report?monthly_report_id=${monthlyId}`
		);
	},
	getDetailGroup(studentId: string, id: string, params: any) {
		return $http.get(`v1/staff/students/${studentId}/groups/${id}`, {
			params,
		});
	},
	getDetailGroupInLibs(studentId: string, params: any) {
		return $http.get(
			`v1/staff/students/${studentId}/groups/no_have_group_library`,
			{
				params,
			}
		);
	},
	deleteGroup(studentId: string, id: string) {
		return $http.delete(`v1/staff/students/${studentId}/groups/${id}`);
	},
	copyGoalReference(id: string, params: any = {}) {
		return $http.post(`v1/org_admin/goal_libraries/${id}/copy`, {
			params,
		});
	},
	copyGoalsReference(params: any = {}) {
		return $http.post(`v1/org_admin/library_items/copy`, params);
	},
};
