import { AxiosResponse } from 'axios';
import { $http } from '@/plugins/axios';

export const groupClassesService = {
	list: (params?: any, isAdmin = true): Promise<AxiosResponse> => {
		return $http.get(`v1/${isAdmin ? 'org_admin' : 'staff'}/group_classes`, {
			params,
		});
	},


	listOverlap: (payload?: any): Promise<AxiosResponse> => {
		return $http.post(
			`v1/org_admin/group_sessions/list_overlapped_group_sessions`,
			payload
		);
	},

	create: (data?: any): Promise<AxiosResponse> => {
		return $http.post(`v1/org_admin/group_classes`, data);
	},

	createSession: (data?: any): Promise<AxiosResponse> => {
		return $http.post(`v1/org_admin/group_sessions`, data);
	},

	detail: (id: string, isAdmin = true): Promise<AxiosResponse> => {
		return $http.get(`v1/${isAdmin ? 'org_admin' : 'staff'}/group_classes/${id}`);
	},


	update: (id: string, data?: any): Promise<AxiosResponse> => {
		return $http.put(`v1/org_admin/group_classes/${id}`, data);
	},

	delete: (id: string): Promise<AxiosResponse> => {
		return $http.delete(`v1/org_admin/group_classes/${id}`);
	},

	block: (id: string): Promise<AxiosResponse> => {
		return $http.put(`v1/org_admin/group_classes/${id}/disable`);
	},

	enable: (id: string): Promise<AxiosResponse> => {
		return $http.put(`v1/org_admin/group_classes/${id}/enable`);
	},

	studentsNotInGroupClass: (
		id: string,
		params?: any
	): Promise<AxiosResponse> => {
		return $http.get(
			`v1/org_admin/group_classes/${id}/students_not_in_group`,
			{ params }
		);
	},

	addStudentToGroupClass: (
		id: string,
		data?: any
	): Promise<AxiosResponse> => {
		return $http.post(
			`v1/org_admin/group_classes/${id}/students_group_classes`,
			data
		);
	},
	addSingleStudentToGroupClass: (
		id: string,
		data?: any
	): Promise<AxiosResponse> => {
		return $http.put(
			`v1/org_admin/group_classes/${id}/students_group_classes/add`,
			data
		);
	},
	removeSingleStudentToGroupClass: (
		id: string,
		data?: any
	): Promise<AxiosResponse> => {
		return $http.put(
			`v1/org_admin/group_classes/${id}/students_group_classes/remove`,
			data
		);
	},

	staffsNotInGroupClass: (
		id: string,
		params?: any
	): Promise<AxiosResponse> => {
		return $http.get(
			`v1/org_admin/group_classes/${id}/staffs_not_in_group`,
			{ params }
		);
	},

	addStaffToGroupClass: (id: string, data?: any): Promise<AxiosResponse> => {
		return $http.post(
			`v1/org_admin/group_classes/${id}/staffs_group_classes`,
			data
		);
	},

	plans: (params?: any): Promise<AxiosResponse> => {
		return $http.get(`v1/staff/group_plans/list_group_classes`, { params });
	},

	activeReports(id: string) {
		return $http.get(`v1/staff/group_classes/${id}/group_plans/active`);
	},

	pendingReports(id: string) {
		return $http.get(`v1/staff/group_classes/${id}/group_plans/pending`);
	},

	sendReport: (id: string, plan_id: string): Promise<AxiosResponse> => {
		return $http.post(
			`v1/staff/group_classes/${id}/group_plans/${plan_id}/send`
		);
	},

	goalsInBank(id: string) {
		return $http.get(`v1/staff/group_classes/${id}/group_goals/library`);
	},

	goalsCompletedByGroupClass(id: string) {
		return $http.get(
			`v1/staff/group_classes/${id}/group_goals?state=completed`
		);
	},

	goalsStoppedByGroupClass(id: string) {
		return $http.get(
			`v1/staff/group_classes/${id}/group_goals?state=stopped`
		);
	},

	goalsByGroupClass(id: string) {
		return $http.get(`v1/staff/group_classes/${id}/group_goals`);
	},

	allGoalsByGroupClass(id: string) {
		return $http.get(`v1/staff/group_classes/${id}/group_goals/all`);
	},

	createByNewGoal(id: string, data: any) {
		return $http.post(`v1/staff/group_classes/${id}/group_goals`, data);
	},

	createByNewGoalInLibs(id: string, data: any) {
		return $http.post(
			`v1/staff/group_classes/${id}/group_goals/library`,
			data
		);
	},

	reportsInHistory(id: string) {
		return $http.get(`v1/staff/group_classes/${id}/group_plans`);
	},

	planOfGroupClass(id: string, planId: string) {
		return $http.get(`v1/staff/group_classes/${id}/group_plans/${planId}`);
	},

	sendPlanOfGroupClass(id: string, planId: string) {
		return $http.get(
			`v1/staff/group_classes/${id}/group_plans/${planId}/send`
		);
	},

	groupTimeSlots(id: string) {
		return $http.get(`v1/org_admin/group_classes/${id}/group_time_slots`);
	},

	getStaffsInGroup(id: string) {
		return $http.get(`v1/org_admin/group_classes/${id}/staffs_in_group`);
	},

	getStaffWhenCreateUnScheduleOverload: (
		id: string,
		params?: any
	): Promise<AxiosResponse> => {
		return $http.post(
			`v1/org_admin/group_sessions/${id}/group_time_slots/list_overlapped`,
			params
		);
	},

	getOverloads: (params?: any): Promise<AxiosResponse> => {
		return $http.post(
			`v1/org_admin/group_sessions/list_overlapped_group_sessions`,
			params
		);
	},

	createTimeSlot(id: string, data: any) {
		return $http.post(
			`v1/org_admin/group_classes/${id}/group_time_slots`,
			data
		);
	},
	updateTimeSlot(id: number, timeSlotId: string, data: any) {
		return $http.put(
			`v1/org_admin/group_classes/${id}/group_time_slots/${timeSlotId}`,
			data
		);
	},

	deleteTimeSlot: (
		id: number,
		timeSlotId: string
	): Promise<AxiosResponse> => {
		return $http.delete(
			`/v1/org_admin/group_classes/${id}/group_time_slots/${timeSlotId}`
		);
	},

	fetchTimeTableGroupOnDay: (params: any): Promise<AxiosResponse> => {
		return $http.get('/v1/org_admin/group_sessions', {
			params,
		});
	},

	fetchReports: (params: any): Promise<AxiosResponse> => {
		return $http.get('/v1/staff/group_lesson_reports', {
			params,
		});
	},
	changeTimeSlot(id: string, payload: any) {
		return $http.put(
			id
				? `v1/org_admin/group_sessions/${id}/update_time`
				: `v1/org_admin/group_sessions/update_time_future_group_session`,
			payload
		);
	},
	cancelScheduleGroupClass(payload: any, id: string) {
		return $http.put(
			id
				? `v1/org_admin/group_sessions/${id}/cancel`
				: `v1/org_admin/group_sessions/cancel_future_group_session`,
			payload
		);
	},
	cancelScheduleGroupClassByStudent(payload: any, id: string) {
		return $http.put(
			id
				? `v1/org_admin/group_sessions/${id}/student_cancel_session`
				: `v1/org_admin/group_sessions/cancel_future_group_session`,
			payload
		);
	},
	batchCancelScheduleGroupClassByStudent(payload: any, isFuture: boolean) {
		return $http.put(
			isFuture
				? `v1/org_admin/group_sessions/batch_cancel_future_student_group_session`
				: `v1/org_admin/group_sessions/batch_cancel_student_group_session`,
			payload
		);
	},
	studentInGroupWithState(id: string, data: any) {
		return $http.post(
			`v1/org_admin/group_classes/${id}/list_students_group_classes`,
			data
		);
	},
	uncancelGroupSession(id: string, payload?: any) {
		return $http.put(`v1/org_admin/group_sessions/${id}/uncancel`, payload);
	},
	uncancelGroupSessionForStudent(payload: any) {
		return $http.put(
			`v1/org_admin/group_sessions/batch_uncancel_student_group_session`,
			payload
		);
	},
	staffListChangeTask(params: any) {
		return $http.get(`v1/org_admin/group_sessions/list_free_staffs`, {
			params,
		});
	},
	changeStaffs(id: number, payload: any) {
		return $http.put(
			id
				? `v1/org_admin/group_sessions/${id}/update_staffs`
				: `v1/org_admin/group_sessions/update_staffs_future_group_session`,
			payload
		);
	},
	getGroupClassReportDetail(id: string, reportId: string, params?: any) {
		return $http.get(
			`v1/staff/group_classes/${id}/group_lesson_reports/${reportId}`,
			{
				params,
			}
		);
	},

	getStudentsAdded(id: string) {
		return $http.post(
			`/v1/org_admin/group_classes/${id}/list_students_group_classes`
		);
	},

	goalDetail(id: string, goal_id: string, params?: any) {
		return $http.get(
			`v1/staff/group_classes/${id}/group_goals/${goal_id}`,
			{
				params,
			}
		);
	},

	doGoalByLib(id: string, plan_id: string, data: any) {
		return $http.post(
			`/v1/staff/group_classes/${id}/group_plans/${plan_id}/group_goal_plans`,
			data
		);
	},

	deleteGoalLib(id: string, group_classes_id: string) {
		return $http.delete(
			`/v1/staff/group_classes/${group_classes_id}/group_goals/${id}`
		);
	},

	removeGoalGroupClasses(
		group_class_id: string,
		group_plan_id: string,
		params: any
	) {
		return $http.delete(
			`/v1/staff/group_classes/${group_class_id}/group_plans/${group_plan_id}/group_goal_plans`,
			{
				params,
			}
		);
	},

	updateGoal(group_classes_id: string, id: string, data: any) {
		return $http.put(
			`/v1/staff/group_classes/${group_classes_id}/group_goals/${id}`,
			data
		);
	},

	getListGroupWithDetail(id: string) {
		return $http.get(`v1/staff/group_classes/${id}/packs_with_goals`);
	},

	getDetailGroup(group_class_id: string, id: string, params?: any) {
		return $http.get(
			`v1/staff/group_classes/${group_class_id}/packs/${id}`,
			{
				params,
			}
		);
	},

	createGroup(group_class_id: string, data: any) {
		return $http.post(
			`v1/staff/group_classes/${group_class_id}/packs`,
			data
		);
	},

	updateNameGroup(group_class_id: string, groupId: string, data: any) {
		return $http.put(
			`v1/staff/group_classes/${group_class_id}/packs/${groupId}`,
			data
		);
	},

	updateGoalIntoGroup(group_class_id: string, groupId: string, data: any) {
		return $http.put(
			`v1/staff/group_classes/${group_class_id}/packs/${groupId}/update_pack_group_goals`,
			data
		);
	},

	deleteGroup(group_class_id: string, id: string) {
		return $http.delete(
			`v1/staff/group_classes/${group_class_id}/packs/${id}`
		);
	},

	fetchByStudentId: (student_id: string): Promise<AxiosResponse> => {
		return $http.get(`v1/org_admin/group_classes`, {
			params: {
				student_id,
			},
		});
	},
};
