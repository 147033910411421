export const _var = {
	TOKEN: 'tk',
	ORGS: 'orgs',
	SUBDOMAIN: 'sub-domain',
};

export const ACTIONS = {
	CREATE: 'create',
	UPDATE: 'update',
	DELETE: 'delete',
	UPDATE_CALENDER: 'update_calender',
};
