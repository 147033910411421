/* eslint-disable */
import { $http } from '@/plugins/axios';
import axios, { AxiosResponse } from 'axios';

export const fileService = {
	getSignatures: (data: any): Promise<AxiosResponse> => {
		return $http.post('v1/s3_upload_signatures/batch', data);
	},
	uploadToS3: (url: string, data: any): Promise<AxiosResponse> => {
		return axios.post('/', data, {
			baseURL: url,
		});
	},
};
