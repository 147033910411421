/* eslint-disable */
import { $http } from '@/plugins/axios';
import { AxiosResponse } from 'axios';

export const notificationService = {
	getNotificationsByYear: (params: any): Promise<AxiosResponse> => {
		return $http.get('v1/org_admin/notices', {
			params,
		});
	},
	getNotificationsByYearForStaffs: (params: any): Promise<AxiosResponse> => {
		return $http.get('v1/org_admin/notices/staff_index', {
			params,
		});
	},
	getNotification: (id: string): Promise<AxiosResponse> => {
		return $http.get(`v1/org_admin/notices/${id}`);
	},
	getParentReadNotification: (id: string): Promise<AxiosResponse> => {
		return $http.get(
			`v1/org_admin/notices/${id}/students_with_parent_read`
		);
	},
	deleteNotification: (id: string): Promise<AxiosResponse> => {
		return $http.delete(`v1/org_admin/notices/${id}`);
	},
	sendNotification: (id: string): Promise<AxiosResponse> => {
		return $http.post(`v1/org_admin/notices/${id}/send`);
	},
	resendNotification: (id: string): Promise<AxiosResponse> => {
		return $http.post(`v1/org_admin/notices/${id}/resend`);
	},
	createNotification: (data: any): Promise<AxiosResponse> => {
		return $http.post('v1/org_admin/notices', data);
	},
	updateNotification: (id: string, data: any): Promise<AxiosResponse> => {
		return $http.put(`v1/org_admin/notices/${id}`, data);
	},
	getFeeNotificationsByYear: (params: any): Promise<AxiosResponse> => {
		return $http.get('v1/org_admin/tuition_notices', {
			params,
		});
	},
	createFeeNotification: (data: any): Promise<AxiosResponse> => {
		return $http.post('v1/org_admin/tuition_notices', data);
	},
	updateFeeNotification: (id: string, data: any): Promise<AxiosResponse> => {
		return $http.put(`v1/org_admin/tuition_notices/notices/${id}`, data);
	},
	getFeeNotification: (id: string): Promise<AxiosResponse> => {
		return $http.get(`v1/org_admin/tuition_notices/notices/${id}`);
	},
	sendFeeNotification: (id: string): Promise<AxiosResponse> => {
		return $http.post(`v1/org_admin/tuition_notices/notices/${id}/send`);
	},
	reSendFeeNotification: (id: string): Promise<AxiosResponse> => {
		return $http.post(`v1/org_admin/tuition_notices/${id}/resend`);
	},
	markPaid: (data: any): Promise<AxiosResponse> => {
		return $http.put(`v1/org_admin/tuitions/mark_as_paid`, data);
	},
	historySendFeeNotification: (id: string): Promise<AxiosResponse> => {
		return $http.get(`v1/org_admin/tuition_notices/${id}/resend_history`);
	},
	deleteFeeNotification: (id: string): Promise<AxiosResponse> => {
		return $http.delete(`v1/org_admin/tuition_notices/notices/${id}`);
	},
};
