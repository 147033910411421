import { defineAsyncComponent } from 'vue';
import { App } from '@vue/runtime-core';

export function globalComponents(app: App): void {
	app.component(
		'DefaultLayout',
		defineAsyncComponent(
			() => import('@/layouts/defaultLayout/DefaultLayout.vue')
		)
	);
	app.component(
		'AuthLayout',
		defineAsyncComponent(
			() => import('@/layouts/authLayout/AuthLayout.vue')
		)
	);
	app.component(
		'NoneLayout',
		defineAsyncComponent(
			() => import('@/layouts/noneLayout/NoneLayout.vue')
		)
	);
	app.component(
		'DraftLayout',
		defineAsyncComponent(
			() => import('@/layouts/draftLayout/DraftLayout.vue')
		)
	);
}
