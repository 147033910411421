<template>
	<div class="_change-status _modal bg-white">
		<div
			class="
				_modal__content
				md-down:px-4
				px-12
				py-10
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<img alt="" src="@/assets/images/icons/luca-pattern.png" />
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
				"
			>
				Phiên bản web {{ props.release.version }} có gì mới
			</div>
			<div class="text-center text-neutral-500 mt-2">
				RA MẮT {{ formatDate(props.release.release_date) }}
			</div>
			<div class="w-full mt-8">
				<div class="flex flex-col gap-5">
					<div
						v-for="i in props.release.features"
						:key="i.id"
						class="flex gap-4"
					>
						<img :src="i.icon.original" alt="" class="w-8 h-8" />
						<div class="flex-1">
							<div class="font-semibold text-base mb-1">
								{{ i.name }}
							</div>
							<div class="font-normal text-sm">
								{{ i.description }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				type="button"
				@click="$emit('close')"
			>
				Đã hiểu
			</button>
			<a
				:href="`${props.release.link}`"
				class="button-p text-center whitespace-nowrap"
				type="button"
				target="_blank"
			>
				Xem thêm
			</a>
		</div>
	</div>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps(['release']);

const formatDate = (date) => {
	const inputDate = new Date(date);
	const day = inputDate.getDate();
	const month = inputDate.getMonth() + 1;
	const year = inputDate.getFullYear();
	const formattedDate = `${day}/${month}/${year}`;
	return formattedDate;
};
</script>
