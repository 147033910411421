
import { ExclamationCircleIcon, XIcon } from '@heroicons/vue/solid';
import { watch } from 'vue';
import { useStore } from 'vuex';

export default {
	name: 'Alert',
	props: {
		isOpen: {
			default() {
				return false;
			},
		},
		type: {
			default() {
				return 'success';
			},
		},
	},
	components: {
		XIcon,
		ExclamationCircleIcon,
	},
	setup(props: any) {
		const store = useStore();

		function setAlert() {
			store.dispatch('setAlertMessage', { message: '', type: 'success' });
		}

		watch(
			() => props.isOpen,
			(value) => {
				if (value) {
					setTimeout(() => {
						setAlert();
					}, 3000);
				}
			}
		);
		return {
			setAlert,
		};
	},
};
