import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a228630c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "da-modal-frame"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isOpen)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isOpen)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "da-modal-frame__background",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "da-modal-frame__wrapper w-full h-full overflow-y-auto fixed left-0 top-0 flex justify-center",
          style: {"z-index":"10001","padding":"48px 0"},
          onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)), ["self"]))
        }, [
          (_ctx.isOpen)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "da-modal-frame__content",
                onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
              }, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}