/* eslint-disable */
import { $http } from '@/plugins/axios';
import { AxiosResponse } from 'axios';

export const lessonReportService = {
	stats: (query?: string): Promise<AxiosResponse> => {
		return $http.get(`/v1/staff/lesson_reports/stats${query}`);
	},
	listLessonReport: (params?: any): Promise<AxiosResponse> => {
		return $http.get(`/v1/staff/lesson_reports/paginate_by_date`, {
			params,
		});
	},
	listLessonReportOldData: (
		studentId: string,
		oldData: any,
		params?: any
	): Promise<AxiosResponse> => {
		return $http.get(
			`v1/staff/students/${studentId}/lesson_reports`,
			oldData
				? {
					params,
					headers: {
						common: oldData,
					},
				}
				: { params }
		);
	},
	listDaycareReportOldData: (
		studentId: string,
		oldData: any,
		params?: any
	): Promise<AxiosResponse> => {
		return $http.get(
			`v1/staff/students/${studentId}/day_care_reports`,
			oldData
				? {
					params,
					headers: {
						common: oldData,
					},
				}
				: {
					params,
				}
		);
	},
	listDayCareReport: (
		query?: string,
		teacher = false
	): Promise<AxiosResponse> => {
		return $http.get(
			`/v1/${teacher ? 'staff' : 'org_admin'}/day_care_reports${query}`
		);
	},
	lessonReportPublic: (query: string): Promise<AxiosResponse> => {
		return $http.get(`/v1/lesson_reports/show_by_token${query}`);
	},
	lesson: (id: any, student_id: string): Promise<AxiosResponse> => {
		return $http.get(
			`/v1/staff/students/${student_id}/lesson_reports/${id}`
		);
	},
	deleteDaycareReport: (
		date: string,
		student_id: any,
		student_class_session_id: string = ''
	): Promise<AxiosResponse> => {
		return $http.delete(`/v1/org_admin/day_care_reports`, {
			params: {
				date,
				student_id,
				student_class_session_id,
			},
		});
	},
	delete: (
		date: string,
		student_id: string,
		session_id: string = ''
	): Promise<AxiosResponse> => {
		return $http.delete(`/v1/org_admin/lesson_reports`, {
			params: {
				date,
				student_id,
				session_id,
			},
		});
	},
	dayCare: (
		id: any,
		student_id: string,
		oldPayload?: any
	): Promise<AxiosResponse> => {
		return $http.get(
			`/v1/staff/students/${student_id}/daily_reports/${id}`,
			oldPayload
				? {
					headers: {
						common: oldPayload,
					},
				}
				: {}
		);
	},
	sendReport: (id: any): Promise<AxiosResponse> => {
		return $http.post(`/v1/staff/lesson_reports/${id}/send`);
	},
	sendDaycareReport: (id: any): Promise<AxiosResponse> => {
		return $http.post(`/v1/staff/day_care_reports/${id}/send`);
	},
	recoverReport: (id: any): Promise<AxiosResponse> => {
		return $http.put(`/v1/staff/lesson_reports/${id}/uncancel`);
	},
};
